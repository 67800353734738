
.login.bg-image {
    background-image: url("../../public/assets/images/bgimg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
  
  .card-body {
    background-color: #F4FFFD;
  }
  
  .card {
    width: 400px;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none !important;
  }
  
  .textfield {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none !important;
  }
  
  .login.login-button {
    width: 100%;
    color: #FFFFFF;
    border: #489C8F;
    background-color: #489C8F;
  }
  
  .login.login-button:hover,
  .login.login-button:focus {
    color: #FFFFFF;
    background-color: #489C8F;
    border-color: #489C8F;
  }
  
  .login.login-button :active,
  .login.login-button :focus:active {
    color: inherit;
    /* Keep the text color as it is */
    background-color: transparent;
    /* Remove the background color */
    border-color: transparent;
    /* Remove the border color */
  }
  
  .button.submit-colors {
    color: #FFFFFF;
    background-color: #489C8F;
    border-color: #489C8F;
    font-size: 1rem;
    /* Relative size */
    font-weight: 500;
    margin-top: 10px;
    /* width: 100%; */
  }
  
  .button.submit-color {
    color: #FFFFFF;
    background-color: #489C8F;
    border-color: #489C8F;
    font-size: 1rem;
    /* Relative size */
    font-weight: 500;
    margin-top: 10px;
    width: 100%;
  }
  
  .button.submit-color:hover,
  button.submit-color:focus {
    color: #FFFFFF;
    background-color: #489C8F;
    border-color: #489C8F;
  }
  
  .button.submit-color:active,
  .button.submit-color:focus:active {
    color: inherit;
    /* Keep the text color as it is */
    background-color: transparent;
    /* Remove the background color */
    border-color: transparent;
    /* Remove the border color */
  }
  
  .button.button-disable {
    color: white;
    background-color: #489C8F;
    width: 100%;
    border: none;
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .button.button-disable:hover {
    color: white;
    background-color: #489C8F;
  }

  .flip-animation {
    animation: flip 1s ease-in-out infinite alternate;
    /* Change the duration and timing function as needed */
  }
  
  @keyframes flip {
    0% {
      transform: rotateY(0deg);
    }
  
    100% {
      transform: rotateY(180deg);
    }
  }